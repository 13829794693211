// used to mark unsupported tokens, these are hosted lists of unsupported tokens

export const UNSUPPORTED_LIST_URLS: string[] = []
export const VanillaMainnetList = 'https://raw.githubusercontent.com/vanilla-labs-org/token-list/main/mainnet.json'

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [
  VanillaMainnetList,
  ...UNSUPPORTED_LIST_URLS, // need to load unsupported tokens as well
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [
  VanillaMainnetList,
  ...UNSUPPORTED_LIST_URLS, // need to load unsupported tokens as well
]
